@import "./vars";

.link_animation {
    position: relative;
    &::before {
        width: 0;
        content: "";
        background-color: $blue;
        height: 3px;
        position: absolute;
        bottom: 0;
        transition: width 0.3s ease-out;
        left: 0;
    }
    &:hover::before {
        width: 100%;
    }

    &_arrow {
        cursor: pointer;
        span {
            display: inline-block;
            transition: transform 0.3s ease-out;
        }
        &:hover span {
            transform: translateX(30px);
        }
    }
}
