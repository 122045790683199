@media only screen and (max-width: 1024px) {
    body {
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
    }

    .works {
        margin-top: 0;
    }
}

@media (min-width: 768px) and (max-width: 1100px) {
    body {
        font-size: 14px;
    }

    .top-header {
        margin: 40px 0;
    }

    .nav-bar {
        width: 100%;
        background: transparent;
    }

    .language-flags {
        margin-left: 20px;
    }

    .nav-bar a {
        margin: 0.5em;
    }

    .container {
        max-width: 100%;
    }

    h3 {
        line-height: 1;
    }

    .works p {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .language-flags {
        margin-left: 0;
    }

    .header {
        .logo img {
            width: 180px;
            margin-left: 0 !important;
        }

        .header__menu {
            margin-right: 0;
        }
    }

    .top-header {
        margin: 25px 0;
    }

    .top-header ~ .container {
        margin: 80px 0 20px;
        padding: 0 25px;
    }

    .logo {
        width: 70%;
    }

    .logo img:last-child {
        margin-left: 1rem;
    }

    .logo .logo-mob {
        display: block;
    }

    .navbar-toggler {
        padding: 0px 24px;
    }

    .nav-bar {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        background: #222d;
        padding: 2em;
    }

    .nav-bar a {
        padding: 1em 1.3em;
    }

    .nav-bar a:last-child {
        line-height: 0.6;
    }

    h1 {
        font-size: 31px;
    }

    h2 {
        line-height: 1;
        font-size: 31px;
        margin: 25px auto;
        text-align: center;
    }

    h3 {
        font-size: 18px;
        line-height: 1;
    }

    h5 {
        line-height: 2;
        font-size: 14px;
        text-align: center;
    }

    .col-lg-2 h3 {
        width: 30px;
        height: 30px;
    }

    .col.mx-1 p {
        font-size: 1em;
    }

    .col.mx-1 {
        -webkit-box-shadow: 0px 18px 94px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 18px 94px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 1em;
        width: 220px;
        height: 270px;
        margin: 0 auto !important;
    }

    .works .col-md-6 {
        height: auto;
        padding: 0 10px;
    }

    .works p {
        font-size: 1em;
    }
}

@media (max-width: 767.98px) {
    .works .col-md-6 {
        padding: 0 !important;
    }
}

@media (min-width: 320px) and (max-width: 400px) {
    body {
        font-size: 13px;
    }

    .nav-bar a:last-child {
        line-height: 1.3;
        text-align: center;
    }

    .col.mx-1 {
        padding: 2em;
    }

    .none-padding {
        padding: 0;
    }
}

@media screen and (orientation: landscape) {
    @media (max-width: 991px) {
        .header .logo img {
            width: 180px;
        }
    }
}
