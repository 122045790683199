.project_animation {
    position: relative;

    &::before {
        opacity: 0.7;
        width: 100%;
        height: 100%;
        content: "";
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 51.25%);
        z-index: 1;
        top: 0;
        left: 0;
        position: absolute;
        pointer-events: none;
        transition: opacity 0.3s;
        z-index: 1;
    }

    &::after {
        opacity: 0;
        width: 100px;
        height: 25px;
        content: "";
        left: 30px;
        bottom: 40px;
        z-index: 1;
        position: absolute;
        pointer-events: none;
        transition: left 0.3s;
        background-image: url("../../assets/img/projectArrow.svg");
        background-repeat: no-repeat;
        transition: opacity 0.3s;
    }

    &:hover::after,
    &:hover::before {
        opacity: 1;
    }
}
