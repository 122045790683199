* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none !important;
    cursor: pointer;
}

body {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.main-container {
    overflow: hidden;
}

header.row {
    position: relative;
}

.top-header {
    display: flex;
    margin: 40px;
    align-items: center;
}

header ul li {
    list-style: none;
}

h1 {
    font-size: 2.5em;
    font-weight: 700;
    line-height: 1.5;
}

.navbar-nav {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-collapse {
    justify-content: flex-end;
}

.navbar-nav a,
.scroll.js-mob-scroll {
    color: #005dd9;
    padding: 7px 14px;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
}

.navbar-nav a {
    margin-left: 1em;
}

.scroll.js-mob-scroll {
    padding: 1em 1.3em;
}

.scroll.js-mob-scroll a:last-child {
    color: #005dd9;
    background-color: #fff;
    width: 150px;
    padding: 8px 14px;
    text-align: center;
    border-radius: 11px;
    transition: all 0.3s ease-out;
    border: 2px solid #fff;
}

.logo {
    width: 50%;
}

.logo img:last-child {
    margin-left: 0;
}

.logo-mob {
    display: none;
}

section {
    margin: 60px 0;
}

.language-flags {
    margin-left: 60px;
}

.col.mx-1 img {
    height: 44px;
    margin-bottom: 30px;
}

h3 {
    font-size: 28px;
    line-height: 2;
    font-weight: 700;
}

.col p {
    font-size: 14px;
    line-height: 2;
}

h5 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 4px;
}

h2 {
    font-weight: 700;
    margin: 30px 0;
    line-height: 1;
    font-size: 64px;
}

.works {
    position: relative;
    z-index: 1;
}

.works h2,
.works h5 {
    text-align: center;
}

.works .col-md-6 {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 1.5em;
    overflow: hidden;
}

.image__zoom {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.image__zoom img {
    width: 100%;
    z-index: 1;
    transition: transform 1s;
    height: 400px;
    object-fit: cover;
    object-position: center top;
}

.image__zoom img:hover {
    transform: scale(1.1);
}

.pos-absolute {
    pointer-events: none;
    position: absolute;
    z-index: 99;
    top: 30px;
    left: 30px;
    color: #fff;
    padding-right: 30px;
}

.works p {
    font-size: 1.2rem;
}

.col-lg-2 h3 {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    border: 2px solid #005dd9;
    color: #005dd9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-lg-2 {
    padding-top: 1em;
}

.aside-text {
    font-size: 0.75rem;
}

h6 {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 2;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:hover .navbar-toggler:active {
    border: none;
    box-shadow: none;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("../../img/equal-sign.png");
}

.pos-absolute h3 {
    line-height: 1;
    margin-bottom: 10px;
}

//mobile menu
.header__menu {
    width: 30px;
    height: 19px;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    position: relative;
    z-index: 99999;
    margin-right: 2em;
}

.header__menu,
.header__menu span {
    display: block;
    transform: rotate(0deg);
    z-index: 3;
}

.header__menu span {
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: $blue;
    opacity: 1;
    left: 0;
    transition: 0.25s ease-in-out;
}

.open.header__menu span {
    background-color: #fff;
}

.header__menu,
.header__menu span {
    display: block;
    transform: rotate(0deg);
    z-index: 10;
}

.header-menu {
    background-color: #b1b1b1;
    height: 100%;
    max-width: 1204px;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    position: fixed;
    right: -1204px;
    top: 0;
    z-index: 9;
    transition: right 0.3s;
    text-align: center;
}

.header-menu.open {
    right: 0;
}

.header-menu ul {
    margin: 0;
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.header__menu span:first-child {
    top: 0;
}

.header__menu span:nth-child(2) {
    top: 8px;
}

.header__menu span:nth-child(3) {
    top: 16px;
}

.header__menu.open span:first-child {
    top: 8px;
    transform: rotate(135deg);
}

.header__menu.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.header__menu.open span:nth-child(3) {
    top: 8px;
    transform: rotate(-135deg);
}
